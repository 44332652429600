import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SiteMetaHook from '../hooks/site-meta-hook'
import PostList from '../components/post-list'
import Pagination from '../components/pagination'

export default ({ data, pageContext }) => {
  const metaData = SiteMetaHook()
  const title = pageContext.title
  const currentCategory = pageContext.slug
  const pageInfo = data.allContentfulBlogPost.pageInfo

  const hrefBuilder = (pageNumber) => {
    return `/stories/${currentCategory}/${pageNumber > 1 ? pageNumber : ''}`
  }

  return (
    <Layout>
      <div>
        <Helmet title={title} />
        <div className="wrap px-6 mx-auto py-8 md:px-20 md:pt-12 md:pb-10">
          <PostList data={data} currentCategory={currentCategory} />
          <Pagination pageInfo={pageInfo} hrefBuilder={hrefBuilder} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CategoryQuery($skip: Int!, $limit: Int!, $slug: String!) {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { category: { slug: { eq: $slug } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "D MMMM, YYYY")
          heroImage {
            fluid(maxWidth: 870, maxHeight: 575, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid_withWebp
            }
            fixed(width: 870, height: 575, quality: 10) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          category {
            slug
            title
            titleTw
          }
          body {
            body
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`
